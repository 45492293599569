import { PropertyConverter } from './property-converter.interface';

export class NumberConverter implements PropertyConverter {
  deserialize(value: any) {
    return value;
  }

  serialize(value: any) {
    if (typeof value !== 'number') {
      return parseFloat(value);
    } else {
      return value;
    }
  }
}
